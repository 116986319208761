import Table, { ColumnsType } from "antd/es/table";
import CurrencyFormatter from "utils/CurrencyFormatter";
import dayjs from "dayjs";
import { useGetBillingHistory } from "../hooks/useGetBillingHistory";

interface DataType {
  key: string;
  name: string;
  date: string;
  amount: number;
  plan: string;
  action: any;
}

const columns: ColumnsType<DataType> = [
  {
    title: "TRX Ref",
    dataIndex: "trans_ref",
    key: "trans_ref"
  },
  {
    title: "Billing date",
    dataIndex: "created_at",
    key: "created_at",
    render:(text)=><>{dayjs(text).format('MMM D, YYYY')}</> ,
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (text) => <CurrencyFormatter value={Number(text)} decimalPrecision={0} />,
  },
  {
    title: "Plan",
    dataIndex: "",
    key: "package",
    render:(text)=><>{text?.package.name} ({text?.plan_duration})</>
  },
  {
    title: "",
    dataIndex: "action",
    key: "action",
    render: (text) => (
      <a className="capitalize text-primary font-medium">{text}</a>
    ),
  },
];

export const BillingHistory = () => {
  const { data, isLoading } = useGetBillingHistory();
  return (
    <div className="bg-white border rounded-md mt-12 px-3 py-5">
      <div className="flex justify-between items-center mb-3">
        <h4 className="font-medium text-lg text-primary"> </h4>
        <button className="button flex items-center gap-2">
          <i className="ri-download-line"></i> <span>Download all</span>
        </button>
      </div>
      <Table scroll={{ x: 500 }} columns={columns} dataSource={data} />
    </div>
  );
};

import React, { useState } from "react";
import { Modal, List, Tooltip, Tag, Radio } from "antd";
import { CheckCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import CurrencyFormatter from "utils/CurrencyFormatter";
import { AppButton } from "components/button/AppButton";
import { useGetRecruiterPackage } from "../hooks/useGetRecruiterPackage";
import { TPackage } from "admin/finance/types";
import { useGetUserInfo } from "hooks/useGetUserInfo";
import { openNotification } from "utils/notification";
import { usePaymentInit } from "../hooks/usePaymentInit";
import { useGetGateways } from "../hooks/useGetGateways";

export const SelectPackages: React.FC<any> = ({ open, handleClose, data }) => {
  const [selectedItems, setSelectedItems] = useState<TPackage[]>([]);
  const [planDuration, setPlanDuration] = useState('quarterly');
  const { data: gateways } = useGetGateways();
  const [paymentGateways, setPaymentGateways] = useState<any>('');
  const { data: packages } = useGetRecruiterPackage();
  const { token,  } = useGetUserInfo();
  const { mutate, isLoading } = usePaymentInit();

  const handleAddItem = (item: TPackage) => {
    setSelectedItems((prevItems) => [...prevItems, item]);
  };
 
  const handleRemoveItem = (index: number) => {
    setSelectedItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const handlePlanDurationChange = (e: any) => {
    setPlanDuration(e.target.value);
  };

  const calculateTotalPrice = () => {
    const base = Number(data.data[planDuration]);
    let total = base;

    selectedItems.forEach((item:any) => {
      let itemAmount = item[planDuration];
      if (item.enterprise_discount) {
        itemAmount = itemAmount - (itemAmount * item.enterprise_discount / 100);
      }
      total += itemAmount;
    });
    return <CurrencyFormatter value={total} decimalPrecision={0} />;
  };

  const handleSubmit = () => {

    mutate(
      {
        plan: data.data.id,
        gateway: paymentGateways,
        plan_duration: planDuration,
        packages: selectedItems,
        upgrade: data.upgrade,
        token,
      },
      {
        onError: (err: any) => {
          openNotification({
            title: "Error",
            state: "error",
            description: "Error occurred try again",
            duration: 4.5,
          });
        },
        onSuccess: (res: any) => {
          console.log(res);
          window.location.assign(res.data.url)
          handleClose(true);
        },
      }
    );
  };

  return (
    <Modal
      title={data.data.name}
      open={open}
      onCancel={() => handleClose(false)}
      footer={null}
      style={{ top: 15 }}
      width="40rem"
    >
      <div>
        <div className="p-4 border border-gray-200 rounded-md">
            <Radio.Group onChange={handlePlanDurationChange} value={planDuration}>
                <Radio value="quarterly">Quarterly</Radio>
                <Radio value="biannually">Biannually</Radio>
                <Radio value="annually">Annually</Radio>
            </Radio.Group> 
        </div>

        <h3 className="text-xs pb-2 pt-8">Select packages that you will later assign to your team (Min 2)</h3>
        <hr />
        <List
          dataSource={packages}
          className="pt-4"
          renderItem={(item:any) => {
            let itemPrice = item[planDuration];
            const discount = itemPrice - (itemPrice * item.enterprise_discount / 100);
            return (
                <List.Item
                    actions={[
                        <Tooltip title="Add">
                        <PlusCircleOutlined className="text-lg" onClick={() => handleAddItem(item)} />
                        </Tooltip>,
                    ]}
                >
                    {item.name} - <CurrencyFormatter value={discount} decimalPrecision={0} />
                </List.Item>
                )
            }
          }
        />
        <hr />
        <h3 className="mt-8 mb-4 font-semibold">Selected Packages</h3>
        {
          
          <div className="p-4 border border-gray-200 rounded-md">
            {
               selectedItems.length > 0 ? selectedItems.map((item:any, index:number) => {
                let itemPrice = item[planDuration];
                const discount = itemPrice - (itemPrice * item.enterprise_discount / 100) ;
                return (
                    <Tag
                        key={index}
                        color="success"
                        closable
                        onClose={() => handleRemoveItem(index)}
                        className="py-1 my-2"
                        icon={<CheckCircleOutlined />}
                    >
                        {item.name} - <CurrencyFormatter value={discount} decimalPrecision={0} />
                    </Tag>
                    )
                }
            ):
            <div className="flex justify-center items-center">
                <h3 className="text-gray-400">No package has been selected yet</h3>
            </div>
            }
          </div>
        }

        {selectedItems.length > 1 && <h3 className="mt-8 font-semibold">Selected Payment gateway</h3>}

        <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-2 text-center justify-center items-center w-1/2">
            {
            selectedItems.length > 1 && gateways?.map((item:any, i ) => (
                <div key={i} onClick={()=>setPaymentGateways(item)} className={`mt-6 shadow-sm w-full cursor-pointer rounded-md ${paymentGateways?.name === item.name && 'border-2 border-green-500 rounded-md'}`}>
                <img src={item.image} alt={item.name} className=" rounded-md w-full" />
                </div>
            ))
            }
        </div>

        { 
            <div className="mt-4 text-right">
                <h3>Total Amount: <span className="font-semibold text-xl"> {calculateTotalPrice()}</span></h3>
            </div>
        }

        <div className="mt-4 text-right">
            <AppButton disabled={selectedItems.length < 2 || !paymentGateways} type="submit" handleClick={()=> handleSubmit()} />
        </div>
      </div>
    </Modal>
  );
};
